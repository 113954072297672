import axios from "axios";
import { headerType, payloadOtpFindId, payloadValidateOtpFIndId } from "../helpers/types";
import baseurl, { baseurlAuth, baseurlCore } from "./axios"


// kumpulan service 
const api = {
  validateOtp: async (data: any) => {
    try {
      const response = baseurl.post('api/v1/migration/otp/validate', data);
      return response;
    } catch (err) {
      return err;
    }
  },
  sendOtp: async (data: any) => {
    try {
      const response = baseurl.post('api/v1/migration/otp/send', data);
      return response;
    } catch (err) {
      return err;
    }
  },
  getUserData: async (email: any) => {
    try {
      const response = baseurl.get(`api/v1/migration/integrated-account/email/${email}`);
      return response;
    } catch (err) {
      return err;
    }
  },
  getUserList: async (data: any) => {
    try {
      const response = baseurl.post('api/v1/migration/integrated-account', data);
      return response;
    } catch (err) {
      return err;
    }
  },
  doSubmit: async (data: any) => {
    try {
      const response = baseurl.post('api/v1/migration/submit', data);
      return response;
    } catch (err) {
      return err;
    }
  },
  getSearchAddttional: async (params: {phone: string, email: string}) => {
    try {
      const response = baseurl.get('/api/v1/migration/integrate-account/search', {
        params,
      });
      return response;
    } catch (err) {
      return err;
    }
  },
  getInfouser: async () => {
    try {
      const response = baseurl.get('/api/v1/migration/getInfouser');
      return response;
    } catch (err) {
      return err;
    }
  },
  updateIsChoose: async (data: any) => {
    try {
      const response = baseurl.post('api/v1/migration/UpdateIsChoose', data);
      return response;
    } catch (err) {
      return err;
    }
  },
  submitMigration: async (data: any) => {
    try {
      const response = baseurl.post('api/v1/migration/submit', data);
      return response;
    } catch (err) {
      return err;
    }
  },
  submitSearchAdditional: async (data: any) => {
    try {
      const response = baseurl.post('api/v1/migration/integrate-account/add', data);
      return response;
    } catch (err) {
      return err;
    }
  },
  otpFindId: async (data: payloadOtpFindId) => {
    try {
      const response = baseurl.post('api/v1/migration/otp/FindId/send', data);
      return response;
    } catch (err) {
      return err;
    }
  },
  getCountryCode: async () => {
    try {
      const response = baseurlAuth.get('/api/v1/Country/listPhoneExt');
      return response;
    } catch (err) {
      return err;
    }
  },
  validateOtpFindId: async (data: payloadValidateOtpFIndId) => {
    try {
      const response = baseurl.post('api/v1/migration/otp/FindId/validate', data);
      return response;
    } catch (err) {
      return err;
    }
  },
  resendOtpFindId: async (key: string) => {
    try {
      const response = baseurl.post('api/v1/MigrationHMG/ReSendOTP/' + key, null);
      return response;
    } catch (err) {
      return err;
    }
  },
  doGetUrlLogin: async (url: string) => {
    try {
      const response = baseurl.get(url);
      return response;
    } catch (err) {
      return err;
    }
  },
  getPrivacyPolicy: async () => {
    try {
      const response = baseurlCore.get('/api/v1/PrivacyPolicy/GetPrivacyPolicy/idm');
      return response;
    } catch (err) {
      return err;
    }
  },
};


export default api


// ClientId:7D2AEC6F-F6A5-4CE6-B93F-33F86066F8E3
// AuthUuid:83371UQ/ltlhGRF124XIJDvb0e7iCN0mPJ2NgfhoAesdD20GgoxrJ1NZ1UfU7ahk
// MigrationId:f976c180-d99f-47e3-949a-afc3b9983bd6
// Accept-Language : id