const Loading = (props: any) => {
  const { description } = props;

  return (
    <div className="loading">
      <div className="lds-ring">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
      {description}
    </div>
  );
};

export default Loading;
