import { FC, useCallback, useEffect, useState } from 'react';
import IdMigration from '../idMigration';
import IntegrateAccount from '../integrateAccount';
import ChooseID from '../chooseId';
import ProgressBar from '../../components/progressBar';
import Icon from '../../components/icon';
import Finished from '../finished';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  getAccountList,
  getInfouser,
  getPrivacyPolicy,
  setAccountData,
  setChosenId,
  updateIsChoose,
} from '../../ssoSlicer/ssoSlice';
import Loading from '../../components/loading';
import { getChosenId } from '../../ssoSlicer/ssoSlice';
import { alertMessage } from '../../helpers/helper';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import MakeProfile from '../makeProfile';
import SearchAdditionalAccount from '../SearchdditionalAccount';
import axios from 'axios';
import ModalInfo from '../../components/modal/ModalInfo/ModalInfo';
import AdditionalAccount from '../additionalAccount';
import { LocalizedString } from '../../shared/localization';
import PrivacyPolicy from '../privacyPolicy';

interface MainProps {}

const Main: FC<MainProps> = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const chosenId = useSelector(getChosenId);
  const accountList = useSelector(getAccountList);
  const [searchParams] = useSearchParams();

  const lang = searchParams.get('lang');
  if (lang) LocalizedString.setLanguage(lang);

  const [step, setStep] = useState(0);
  const [accounts, setAccounts] = useState<any[]>([]);
  const [chosenIds, setChosenIds] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [showModalMakeProfile, setShowModalMakeProfile] = useState<boolean>(false);
  const [showModalPrivaacyPolicy, setShowModalPrivacyPolicy] = useState<boolean>(false);

  const [showConfirmModal, setShowConfirmModal] = useState<boolean>(false);
  const [header, setHeader] = useState<any>(null);
  const [modalSearchAdditionalAccount, setModalSearchAdditionalAccount] = useState<boolean>(false);
  const [modalAddAdditionalAccount, setModalAddAdditionalAccount] = useState<boolean>(false);
  // const []
  const [hasBluelink, setHasBluelink] = useState<boolean>(false);
  const [bluelinkSelected, setBluelinkSelected] = useState<boolean>(false);

  const totalStep = 3;
  let hasFooter = false;
  const titles = [
    LocalizedString.idMigration.labelSearchYourAccount,
    LocalizedString.idMigration.labelSearchResults,
    LocalizedString.idMigration.labelChooseIDToUse,
  ];
  // const titles = ['Search Your Account', 'Search Results', 'Choose ID to Use'];

  const handleSetID = useCallback(() => {
    const clientId = searchParams.get('clientId') || searchParams.get('clientid') || '';
    const mid = searchParams.get('mid') || '';
    localStorage.setItem('clientId', clientId);
    localStorage.setItem('mid', mid);
  }, [searchParams]);

  useEffect(() => {
    setChosenIds([]);
    handleSetID();
    dispatch(getInfouser())
      .unwrap()
      .then((res: any) => dispatch(setAccountData(res)));
  }, [searchParams, handleSetID, dispatch]);

  useEffect(() => {
    if (accountList && accountList.length) {
      setAccounts(accountList);

      const bluelinkAccs = accountList?.filter((acc: any) => acc.platform === 'Bluelink');
      if (bluelinkAccs.length) {
        const acc = bluelinkAccs[0];
        const accLength = acc.account.length;
        setHasBluelink(accLength ? true : false);
      }
    }
    if (chosenId && chosenId.length) {
      setChosenIds(chosenId);
      const isBluelinkChoosed = chosenId.filter((id: any) => id.platform === 'Bluelink');
      setBluelinkSelected(isBluelinkChoosed.length ? true : false);
    }
  }, [accountList, chosenId]);

  const handleNext = () => {
    if (step === 1) {
      if (!chosenIds.length) {
        alertMessage.error(LocalizedString.idMigration.errMessageNext);
        // alertMessage.error('Please select an account');
      } else {
        const ids = chosenIds.map((v) => {
          return { id: v.id };
        });
        dispatch(updateIsChoose(ids))
          .unwrap()
          .then((res: any) => {
            setStep((prevActiveStep) => prevActiveStep + 1);
          });

        setHeader(null);
      }
    } else if (step === 2) {
      setShowModalMakeProfile(true);
    } else {
      setStep((prevActiveStep) => prevActiveStep + 1);
    }
  };
  const handleSelectionChange = (data: any) => {
    // let arr = [];
    // if (data.Bluelink !== '') {
    //   const accData = accounts[0].account.filter((d: any) => d.id === data.Bluelink);
    //   if (accData.length) {
    //     arr.push({ ...accData[0], platform: 'Bluelink' });
    //   }
    // }
    // if (data.myHyundai !== '') {
    //   const accData = accounts[1].account.filter((d: any) => d.id === data.myHyundai);
    //   if (accData.length) {
    //     arr.push({ ...accData[0], platform: 'myHyundai' });
    //   }
    // }
    // if (data.Clicktobuy !== '') {
    //   const accData = accounts[2].account.filter((d: any) => d.id === data.Clicktobuy);
    //   if (accData.length) {
    //     arr.push({ ...accData[0], platform: 'Clicktobuy' });
    //   }
    // }
    dispatch(setChosenId(data));
  };
  const handleSelectionChangeHeader = (data: any) => {
    setHeader(data);
  };
  const handleBack = () => {
    setStep((prevActiveStep) => prevActiveStep - 1);
  };
  const handleCloseProfile = () => {
    setShowModalMakeProfile(false);
  };
  const handleClosePrivacy = () => {
    setShowModalPrivacyPolicy(false);
  };
  const handleFinish = () => {
    setShowModalMakeProfile(false);
    setStep((prevActiveStep) => prevActiveStep + 1);
  };
  const handleModalAdditionalAccount = () => {
    setModalSearchAdditionalAccount(true);
  };
  const hideModalAdditionalAccount = () => {
    setModalSearchAdditionalAccount(false);
  };

  const handleOpenPrivacyPolicy = async () => {
    await dispatch(getPrivacyPolicy());
    await setShowModalPrivacyPolicy(true);
  };
  const getStepsContent = (stepIndex: number) => {
    switch (stepIndex) {
      case 0:
        hasFooter = false;
        return <IdMigration onNext={handleNext} />;
      case 1:
        hasFooter = true;
        return (
          <IntegrateAccount
            data={accounts}
            onSelectionChange={handleSelectionChange}
            setModalAdditionalAccount={handleModalAdditionalAccount}
          />
        );
      case 2:
        hasFooter = true;
        return <ChooseID onSelectionChange={handleSelectionChangeHeader} />;
      case 3:
        hasFooter = false;
        return <Finished account={header} />;
    }
  };

  const isMainButtonDisable = (): boolean => {
    return (
      (step === 1 && !chosenIds.length) || (step === 1 && hasBluelink && !bluelinkSelected) || (step == 2 && !header)
    );
  };

  return (
    <>
      <div className="flex flex-col h-screen">
        {step < 3 && (
          <>
            <header className="p-4 flex justify-between items-center">
              <div className="flex items-center justify-between w-full">
                <button style={{ paddingRight: 16 }} onClick={() => setShowConfirmModal(true)}>
                  <Icon icon="close" size={20} />
                </button>
                <strong className="font-1">{LocalizedString.idMigration.title}</strong>
                <span style={{ width: 36, height: 1 }}>&nbsp;</span>
              </div>
            </header>
            <header className="p-4 flex justify-between items-center">
              <div className="flex items-center">
                {step > 0 && (
                  <button style={{ paddingRight: 16 }} onClick={handleBack}>
                    <Icon icon="arrow-left" size={20} />
                  </button>
                )}
                <strong className="font-1">{titles[step]}</strong>
              </div>
              <span>
                {step + 1}/{totalStep}
              </span>
            </header>
            <ProgressBar bgcolor={'#00AAD2'} step={step + 1} total={totalStep} />
          </>
        )}

        <main className={`flex-1 overflow-y-auto p-5 bg-background ${step === 0 || step === 3 ? 'flex' : ''}`}>
          {getStepsContent(step)}
        </main>

        {hasFooter && (
          <footer className="p-4 text-center flex justify-center flex-row items-center">
            {/* <button onClick={handleBack}>Back</button> */}
            <div className="w-full md:w-[480px]">
              <button
                onClick={handleNext}
                disabled={isMainButtonDisable()}
                className="bg-primary hover:bg-secondary text-white font-bold py-3 px-4 w-full"
              >
                {step === 1 ? 'Migration ID' : 'Submit'}
              </button>
            </div>
          </footer>
        )}
      </div>
      <ToastContainer
        position="bottom-center"
        autoClose={5000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
      {loading && <Loading description={LocalizedString.idMigration.loadingDesc} />}

      {showModalMakeProfile && header && (
        <MakeProfile
          onNext={handleFinish}
          onBack={handleCloseProfile}
          account={header}
          onShowPrivacyPolicy={handleOpenPrivacyPolicy}
        />
      )}
      {showModalPrivaacyPolicy && header && <PrivacyPolicy onBack={handleClosePrivacy} />}
      {modalSearchAdditionalAccount && (
        <SearchAdditionalAccount
          onBack={hideModalAdditionalAccount}
          onConfirm={() => {
            setModalSearchAdditionalAccount(false);
            setModalAddAdditionalAccount(true);
          }}
        />
      )}
      {modalAddAdditionalAccount && (
        <AdditionalAccount
          onBack={() => {
            setModalAddAdditionalAccount(false);
            setModalSearchAdditionalAccount(true);
          }}
          onBackSucces={() => setModalAddAdditionalAccount(false)}
        />
      )}
      <ModalInfo
        title={LocalizedString.idMigration.titleCancelIdMigration}
        desc={LocalizedString.idMigration.descCancelIdMigration}
        btnTextConfirm={LocalizedString.idMigration.labelYesCancelIdMigration}
        btnTextCancel={LocalizedString.idMigration.labelNoCancelIdMigration}
        show={showConfirmModal}
        onCloseModal={() => setShowConfirmModal(false)}
        onCancel={() => setShowConfirmModal(false)}
        onConfirm={() => {
          if (!showConfirmModal) return;
          navigate(`/hyundai-one-id?clientId=${localStorage.getItem('clientId')}`);
        }}
      />
    </>
  );
};

export default Main;
