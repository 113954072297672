const ProgressBar = (props: any) => {
    const { bgcolor, step, total } = props;
    const completed = step / total * 100;
  
    const containerStyles = {
      height: 8,
      width: '100%',
      backgroundColor: "#f1f1f1",
    }
  
    const fillerStyles = {
      height: '100%',
      width: `${completed}%`,
      backgroundColor: bgcolor,
    }
  
    return (
      <div style={containerStyles}>
        <div style={fillerStyles}>
        </div>
      </div>
    );
  };
  
  export default ProgressBar;