import { FC, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

interface LandingProps { }

const Landing: FC<LandingProps> = () => {
  const [searchParams] = useSearchParams();
  const redirectUrl: string = searchParams.get('urlredirect') || '';

  const [message, setMessage] = useState<string>('');

  useEffect(() => {
    openPage();
  }, []);

  /**
   * Open page from query parameter.
   * @param e Click event.
   */
  const openPage = (e?: any) => {
    // console.log('event', e);
    e?.preventDefault();

    const validScheme: string = 'myhyundaiindonesia://';

    if (redirectUrl.startsWith(validScheme)) {
      window.open(redirectUrl);
    } else {
      setMessage('Failed to redirect. Invalid URL.');
    }
  }

  return (
    <div className="flex flex-col justify-center items-center h-screen bg-background p-4">
      <div className="flex flex-col justify-center items-center mb-4">
        <h1 className="text-lg text-center text-black hyundaiTextHead mb-2">Redirecting to myHyundai app...</h1>

        <p className="text-sm text-center">
          If nothing happened, <a href="#" className="cursor-pointer text-secondary" onClick={openPage}>click here</a> to open the app manually.
        </p>
      </div>

      <p className="text-sm text-center text-danger">{message}</p>
    </div>
  )
}

export default Landing;
