import { FC } from 'react';
import Icon from '../../components/icon';
import { LocalizedString } from '../../shared/localization';

interface FinishedProps {
  account?: any;
}
const Finished: FC<FinishedProps> = ({ account }) => {
  return (
    <div className="font-sans w-full flex flex-row justify-center items-center flex-1">
      <div>
        <div className="w-20 aspect-square mx-auto rounded-full bg-secondary flex justify-center items-center">
          <Icon icon="checkmark" size={40} color="#ffffff" />
        </div>
        <div className="text-center mt-5 text-xl font-medium">
          <strong>{LocalizedString.idMigration.labelCongratulations}</strong>
        </div>
        <div className="text-center mt-1 font-light text-md">
          <p>{LocalizedString.idMigration.labelYouHaveFinishedIDMigrationProcess}</p>
        </div>
        <div className="flex flex-col mt-8">
          <a
            href={`${process.env.REACT_APP_API_URL}/api/v1/migration/success/${localStorage.getItem('mid')}/${localStorage.getItem('clientId')}`}
            className="flex-1 bg-primary hover:bg-secondary text-white text-center py-3 px-4"
          >
            {LocalizedString.idMigration.labelBtnContinue}
          </a>
        </div>
      </div>
    </div>
  );
};

export default Finished;
