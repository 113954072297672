import React, { FC } from 'react'
import Icon from '../../components/icon'
import { LocalizedString } from '../../shared/localization';
import { initialStateProps } from '../../helpers/types';
import { useSelector } from 'react-redux';

interface PrivacyPolicyProps {
  onBack: () => void;
}

const PrivacyPolicy: FC<PrivacyPolicyProps> = ({ onBack }) => {
  const { privacyPolicy } = useSelector((state: initialStateProps) => state);
  return (
    <div className="fixed w-full h-full z-10 top-0 left-0 bg-white overflow-auto flex flex-col">
      <header className="p-4 flex items-center bg-white sticky top-0 z-50">
        <button style={{ paddingRight: 16, lineHeight: 0 }} onClick={onBack}>
          <Icon icon="close" size={20} />
        </button>
        <strong className="font-1">{LocalizedString.idMigration.titlePrivacyPolicy}</strong>
        <div>&nbsp;</div>
      </header>
      <div className="p-4" dangerouslySetInnerHTML={{ __html: privacyPolicy }}></div>
    </div>
  );
};

export default PrivacyPolicy