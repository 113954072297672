import { Route, Routes } from 'react-router-dom';

import FindId from './pages/findId';
import HyundaiOneId from './pages/hyundaiOneId';
import Landing from './pages/landing';
import Main from './pages/main';
import Success from './pages/succes';

function App() {
  return (
    //  <React.StrictMode>
    <Routes>
      <Route path="/" element={<Main />} />
      <Route path="/findid" element={<FindId />} />
      <Route path="/find-ctb-account" element={<FindId source="ctb" />} />
      <Route path="/success" element={<Success />} />
      <Route path="/hyundai-one-id" element={<HyundaiOneId />} />

      <Route path="/landing" element={<Landing />} />
    </Routes>
    // </React.StrictMode>
  );
}

export default App;
