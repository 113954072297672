import React, { FC, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Icon from '../../components/icon';
import ModalOtp from '../../components/modal/ModalOtp/ModalOtp';
import { alertMessage } from '../../helpers/helper';
import {
  initialStateProps,
  listSelectAdditional,
  myhyundai,
  paramsFunctionOnSelect,
  payloadOtpAdditional,
} from '../../helpers/types';
import {
  getSearchAddttional,
  onGetUserList,
  onSendEmailOtp,
  onValidateOtp,
  setAccountList,
  submitSearchAdditional,
  setIsAbleToSearch,
  setAddtionalAccountMyhyundai,
  setAddtionalAccountBlueLink,
  setAddtionalAccountCtb,
  setSelcetAdditional,
} from '../../ssoSlicer/ssoSlice';
import Loading from '../../components/loading';
import { LocalizedString } from '../../shared/localization';

interface SearchAdditionalAccountProps {
  onBack?: any;
  onConfirm: () => void;
}
const SearchAdditionalAccount: FC<SearchAdditionalAccountProps> = ({onBack, onConfirm}) => {
  const dispatch = useDispatch();

  const [showModalOtp, setShowModalOtp] = useState<boolean>(false);
  const [emailOtp, setEmailOtp] = useState<string>('');
  const { ctb, bluelink, myhyundai } = useSelector((state: initialStateProps) => state.listSearchAddtional);
  const { key, id } = useSelector((state: initialStateProps) => state.otpResponse);
  const { phone, email } = useSelector((state: initialStateProps) => state.paramsAditional);
  const { additonalBy } = useSelector((state: initialStateProps) => state);
  const { loading } = useSelector((state: initialStateProps) => state);
  const [select, setSelect] = useState<listSelectAdditional[]>([]);
  const [currentData, setCurrentData] = useState<any>(null);

  const handleChecked = (e: any) => {
    console.log('event', e.target);
    // const { name, value } = e.target;
  };

  const Header = () => (
    <header className="p-4 flex justify-between items-center bg-white sticky top-0 z-10">
      <button style={{ paddingRight: 16 }} onClick={onBack}>
        <Icon icon="arrow-left" size={20} />
      </button>

      <strong className="font-1 mx-auto">{LocalizedString.idMigration.labelSearchAdditionalAccount}</strong>
      <div></div>
    </header>
  );

  const onSendOtpHandler = (res: myhyundai) => {
    setCurrentData(res);
    const payload: payloadOtpAdditional = {
      EMAIL: res.email,
      REQUESTUSING: 'EMAIL',
      KEY: res.key,
    };
    setEmailOtp(res.email);
    dispatch(onSendEmailOtp(payload))
      .unwrap()
      .then((res: any) => {
        if(res?.status && res?.status === 500 || res?.status && res?.status === 400){
          const message = res?.message;
          alertMessage.error(message);
          return;
        }
        setShowModalOtp(true);
      }).catch((err: any) => {
        if (err?.response?.status === 500 || err?.response?.status === 400) {
          const message = err?.response?.data?.Message.split('|')[0];
          alertMessage.error(message);
          return;
        }
    });
  };

  const onValidateOtpHandler = (otp: string) => {
    const payload = {
      IdOtpSSO: id,
      Key: key,
      OTP: otp,
      PhoneNumber: '',
      Email: emailOtp,
      RequestUsing: 'email',
      Application: '',
      OtpFrom: 'SearchAddAccount',
    };
    dispatch(onValidateOtp(payload))
      .unwrap()
      .then((res: any) => {
        if (res.status === 200) {
          const payloadAdditional = {
            email,
            phone,
          };
          dispatch(getSearchAddttional(payloadAdditional));
          setShowModalOtp(false);
        }
      }).catch((err: any) => {
          if (err?.response?.status === 500 || err?.response?.status === 400) {
            const message = err?.response?.data?.Message.split('|')[0];
            alertMessage.error(message);
            return;
          }
      });
  };

  const onSelectHandler = (e: any) => {
    const selectedEmail: string = e.target.value;

    if (e.target.checked) {
      const tempAccounts: any[] = [
        bluelink.find(d => d.email == selectedEmail),
        myhyundai.find(d => d.email == selectedEmail),
        ctb.find(d => d.email == selectedEmail),
      ];

      const selectedAccounts: listSelectAdditional[] = tempAccounts.filter(Boolean).map(a => {
        let temp: listSelectAdditional = {
          MigrationId: Number(localStorage.getItem('idMigration')),
          Email: a.email,
          PhoneNumber: a.phone,
          Application: a.application,
          Name: a.name,
          Dob: a.dob,
          Key: a.key,
        };

        return temp;
      });

      setSelect(prevValue => ([...prevValue, ...selectedAccounts]));
    } else {
      const filter = select.filter(a => a.Email !== selectedEmail);
      setSelect(filter);
    }
  };

  const onSaveHandler = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    const hyundai = select.find((item) => item.Application === 'myhyundai');
    const bluelink = select.find((item) => item.Application === 'bluelink');
    const ctb = select.find((item) => item.Application === 'ctb');
    dispatch(setSelcetAdditional(select));
    dispatch(setAddtionalAccountMyhyundai(hyundai ? [hyundai] : []));
    dispatch(setAddtionalAccountBlueLink(bluelink ? [bluelink] : []));
    dispatch(setAddtionalAccountCtb(ctb ? [ctb] : []));
    onConfirm();
  };
  // const onSaveHandler = (e: any) => {
  //   e.preventDefault();
  //   console.lo100000019g(select);
  //   dispatch(submitSearchAdditional(select))
  //   .unwrap()
  //   .then((res: any) => {
  //     console.log('res',res);
  //     if(res){
  //       const email = localStorage.getItem('currentEmail');
  //       const phone = localStorage.getItem('currentPhoneNumber');
  //       GetUserLists(phone, email);
  //       dispatch(setIsAbleToSearch(false));
  //     }else{
  //       alertMessage.error("Failed to add your account")
  //     }
  //   }).catch((err: any) => alertMessage.error("Failed to add your account"));
  // };

  // const GetUserLists = (phone: any, email: any) => {
  //   const payload = {
  //     "email" : email,
  //     "phoneNumber": phone,
  //     "source": "CtbMyIDM",
  //   }
  //   dispatch(onGetUserList(payload))
  //     .unwrap()
  //     .then((res: any) => {
  //       console.log(res);
  //       if (res) {
  //         localStorage.setItem('idMigration', res.idMigration);
  //         const {bluelink, ctb, myhyundai} = res;
  //         const data = [
  //           {
  //             platform: 'Bluelink',
  //             account: bluelink
  //           },
  //           {
  //             platform: 'myHyundai',
  //             account: myhyundai
  //           },
  //           {
  //             platform: 'Clicktobuy',
  //             account: ctb,
  //           },
  //         ];
  //         dispatch(setAccountList(data));
  //         onBack();
  //       }
  //     })
  //     .catch((err: any) => console.log(err, "ini rerorr"));
  // }

  const isSelected = (account: paramsFunctionOnSelect): boolean => {
    const selectedAccount = select.find(a => a.Email == account.email && a.Application == account.application);

    return Boolean(selectedAccount);
  }

  return (
    <>
      <div className="fixed w-full h-full z-10 top-0 left-0 bg-background overflow-auto">
        <Header />
        <div className="w-full md:w-[480px] mx-auto">
          <div className="flex flex-col items-center py-8 px-6">
            <div className="flex flex-col items-start md:w-[480px]">
              <h1>{LocalizedString.idMigration.labelWeFoundSomeAccountsThatUsingEmail} {additonalBy}</h1>
              <p>{LocalizedString.idMigration.labelPleaseVerifyAdditionalAccountToIntegrate}</p>
            </div>

            {myhyundai?.length ? (
              <div className="flex flex-col items-start py-4 my-4 border-2 border-border bg-white w-full md:w-[480px] lg:h-auto">
                <div className="flex flex-col items-start px-4">
                  <p className="text-xs text-neutral">{LocalizedString.idMigration.labelPlatform}</p>
                  <h1 className="text-lg text-primary ">{LocalizedString.idMigration.labelMyHyundai}</h1>
                </div>
                <div className="flex flex-col items-start self-stretch ">
                  {myhyundai.map((res) => (
                    <div key={res.id} className="flex flex-col px-4 self-stretch">
                      {res.isVerify ? (
                        <div className="flex   flex-row justify-between items-center  self-stretch flex-1 py-4 border-b border-border">
                          <div className="mr-2 grow-0">
                            <Icon icon="email" size={20} />
                          </div>
                          <div className="flex flex-col items-start lg:flex-row  w-full">
                            <p className="text-sm text-black grow flex-1 ">{res.email}</p>
                            <p className="bg-secondary py-1 px-2 text-white text-xs mr-2">{LocalizedString.idMigration.labelVerified}</p>
                          </div>
                          <input
                            className=" grow-0 h-5 w-5 text-secondary border-secondary focus:ring-0 focus:ring-offset-0 cursor-pointer"
                            type="checkbox"
                            onChange={(e) => onSelectHandler(e)}
                            checked={isSelected(res)}
                            value={res.email}
                          />
                        </div>
                      ) : (
                        <div>
                          <div className="flex   flex-row justify-between items-center  self-stretch flex-1 py-4 border-b border-border">
                            <div className="mr-2 grow-0">
                              <Icon icon="email" size={20} />
                            </div>

                            <div className="flex flex-col items-start lg:flex-row  w-full">
                              <p className="text-sm text-black grow flex-1 ">{res.email}</p>
                              <p className="bg-danger py-1 px-2 text-white text-xs mr-2">{LocalizedString.idMigration.labelNotVerified}</p>
                            </div>
                            <input
                              className=" grow-0 h-5 w-5 text-secondary border-secondary focus:ring-0 focus:ring-offset-0"
                              type="checkbox"
                              onChange={(e) => onSelectHandler(e)}
                              value={res.email}
                              disabled
                            />
                          </div>
                          <button
                            className="py-2 px-4 border border-primary my-2 text-sm w-full"
                            onClick={onSendOtpHandler.bind(null, res)}
                          >
                            {LocalizedString.idMigration.labelBtnSendVerificationCode}
                          </button>
                        </div>
                      )}
                    </div>
                  ))}
                </div>
              </div>
            ) : (
              ''
            )}
            {ctb?.length ? (
              <div className="flex flex-col items-start py-4 my-4 border-2 border-border bg-white w-full md:w-[480px] lg:h-auto">
                <div className="flex flex-col items-start px-4">
                  <p className="text-xs text-neutral">{LocalizedString.idMigration.labelPlatform}</p>
                  <h1 className="text-lg text-primary ">{LocalizedString.idMigration.labelClickToBuy}</h1>
                </div>
                <div className="flex flex-col items-start self-stretch ">
                  {ctb.map((res) => (
                    <div key={res.id} className="flex flex-col px-4 self-stretch">
                      {res.isVerify ? (
                        <div className="flex   flex-row justify-between items-center  self-stretch flex-1 py-4 border-b border-border">
                          <div className="mr-2 grow-0">
                            <Icon icon="email" size={20} />
                          </div>
                          <div className="flex flex-col items-start lg:flex-row  w-full">
                            <p className="text-sm text-black grow flex-1 ">{res.email}</p>
                            <p className="bg-secondary py-1 px-2 text-white text-xs mr-2">{LocalizedString.idMigration.labelVerified}</p>
                          </div>
                          <input
                            className=" grow-0 h-5 w-5 text-secondary border-secondary focus:ring-0 focus:ring-offset-0 cursor-pointer"
                            type="checkbox"
                            onChange={(e) => onSelectHandler(e)}
                            checked={isSelected(res)}
                            value={res.email}
                          />
                        </div>
                      ) : (
                        <div>
                          <div className="flex   flex-row justify-between items-center  self-stretch flex-1 py-4 border-b border-border">
                            <div className="mr-2 grow-0">
                              <Icon icon="email" size={20} />
                            </div>

                            <div className="flex flex-col items-start lg:flex-row  w-full">
                              <p className="text-sm text-black grow flex-1 ">{res.email}</p>
                              <p className="bg-danger py-1 px-2 text-white text-xs mr-2">{LocalizedString.idMigration.labelNotVerified}</p>
                            </div>
                            <input
                              className=" grow-0 h-5 w-5 text-secondary border-secondary focus:ring-0 focus:ring-offset-0"
                              type="checkbox"
                              onChange={(e) => onSelectHandler(e)}
                              value={res.email}
                              disabled
                            />
                          </div>
                          <button
                            className="py-2 px-4 border border-primary my-2 text-sm w-full"
                            onClick={onSendOtpHandler.bind(null, res)}
                          >
                            {LocalizedString.idMigration.labelBtnSendVerificationCode}
                          </button>
                        </div>
                      )}
                    </div>
                  ))}
                </div>
              </div>
            ) : (
              ''
            )}
            {bluelink?.length ? (
              <div className="flex flex-col items-start py-4 my-4 border-2 border-border bg-white w-full md:w-[480px] lg:h-auto">
                <div className="flex flex-col items-start px-4">
                  <p className="text-xs text-neutral">{LocalizedString.idMigration.labelPlatform}</p>
                  <h1 className="text-lg text-primary ">{LocalizedString.idMigration.labelBluelink}</h1>
                </div>
                <div className="flex flex-col items-start self-stretch ">
                  {bluelink.map((res) => (
                    <div key={res.id} className="flex flex-col px-4 self-stretch">
                      {res.isVerify ? (
                        <div className="flex   flex-row justify-between items-center  self-stretch flex-1 py-4 border-b border-border">
                          <div className="mr-2 grow-0">
                            <Icon icon="email" size={20} />
                          </div>
                          <div className="flex flex-col items-start lg:flex-row  w-full">
                            <p className="text-sm text-black grow flex-1 ">{res.email}</p>
                            <p className="bg-secondary py-1 px-2 text-white text-xs mr-2">{LocalizedString.idMigration.labelVerified}</p>
                          </div>
                          <input
                            className=" grow-0 h-5 w-5 text-secondary border-secondary focus:ring-0 focus:ring-offset-0 cursor-pointer"
                            type="checkbox"
                            onChange={(e) => onSelectHandler(e)}
                            checked={isSelected(res)}
                            value={res.email}
                          />
                        </div>
                      ) : (
                        <div>
                          <div className="flex   flex-row justify-between items-center  self-stretch flex-1 py-4 border-b border-border">
                            <div className="mr-2 grow-0">
                              <Icon icon="email" size={20} />
                            </div>

                            <div className="flex flex-col items-start lg:flex-row  w-full">
                              <p className="text-sm text-black grow flex-1 ">{res.email}</p>
                              <p className="bg-danger py-1 px-2 text-white text-xs mr-2">{LocalizedString.idMigration.labelNotVerified}</p>
                            </div>
                            <input
                              className=" grow-0 h-5 w-5 text-secondary border-secondary focus:ring-0 focus:ring-offset-0"
                              type="checkbox"
                              onChange={(e) => onSelectHandler(e)}
                              value={res.email}
                              disabled
                            />
                          </div>
                          <button
                            className="py-2 px-4 border border-primary my-2 text-sm w-full "
                            onClick={onSendOtpHandler.bind(null, res)}
                          >
                            {LocalizedString.idMigration.labelBtnSendVerificationCode}
                          </button>
                        </div>
                      )}
                    </div>
                  ))}
                </div>
              </div>
            ) : (
              ''
            )}
            <button
              onClick={onSaveHandler}
              disabled={!select.length}
              className=" flex flex-col items-center  py-[14px] px-6 bg-primary text-white text-base w-full md:w-[480px] disabled:bg-gray-400"
            >
              {LocalizedString.idMigration.labelBtnContinue}
            </button>
          </div>
        </div>
      </div>
      {loading && <Loading />}
      {showModalOtp && <ModalOtp
        show={showModalOtp}
        title={LocalizedString.idMigration.titleOtpCode}
        desc={`${LocalizedString.idMigration.descEmailA} ${emailOtp} ${LocalizedString.idMigration.descEmailB}`}
        onCloseModal={() => setShowModalOtp(false)}
        onConfirm={onValidateOtpHandler}
        btnTextConfirm={LocalizedString.idMigration.btnVerifyOtp}
        loading={false}
        onResendOtp={() => onSendOtpHandler(currentData)}
      />}
    </>
  );
};

export default SearchAdditionalAccount;
