import React, { FC, useEffect, useState } from 'react';
import Icon from '../../components/icon';
import { useDispatch, useSelector } from 'react-redux';
import { getChosenId, getSearchAddttional, setAdditionalBy, setParamsAditional } from '../../ssoSlicer/ssoSlice';
import PlatformAcount from '../../components/platformAccount';
import ModalSearch from '../../components/modal/ModalSearch/ModalSearch';
import { initialStateProps, paramsAdditional } from '../../helpers/types';
import { alertMessage } from '../../helpers/helper';
import { LocalizedString } from '../../shared/localization';

type Platform = 'Bluelink' | 'Clicktobuy' | 'myHyundai';

interface DataArray {
  platform: Platform;
  account: Array<any>;
}
interface IntegrateAccountProps {
  data: Array<DataArray>;
  onSelectionChange?: any;
  setModalAdditionalAccount?: any;
}

interface StateIntegrateProps {
  Bluelink?: number;
  Clicktobuy?: number;
  myHyundai?: number;
}

const IntegrateAccount: FC<IntegrateAccountProps> = ({ data, onSelectionChange, setModalAdditionalAccount }) => {
  const [modal, setModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [valSearch, setValSearch] = useState<string>('');
  const { isAbleToSearch } = useSelector((state: initialStateProps) => state);

  const chosenId: any[] = useSelector(getChosenId);

  const dispatch = useDispatch()

  const [stateIntegrate, setStateIntegrate] = useState<StateIntegrateProps>(
    {
      Bluelink: undefined,
      Clicktobuy: undefined,
      myHyundai: undefined,
    }
  );

  useEffect(() => {
    const selectedAccountIds = data.map(d => {
      const selected: any = chosenId.find(a => a.platform == d.platform);
      const account: any = d.account.find(a => a.email == selected?.email);

      return {
        ...d,
        account,
      };
    }).reduce((a, b) => ({ ...a, [b.platform]: b.account?.id }), {});

    setStateIntegrate(selectedAccountIds);
  }, []);

  const handleOptionChange = (platform: string, e: any) => {
    const selectedEmail: string = data.find(d => d.platform == platform)?.account.find(a => a.id == e)?.email;
    const selectedAccounts = data.map(d => ({ ...d, account: d.account.find(a => a.email == selectedEmail) }));

    const selectedData = selectedAccounts.filter(a => a.account).map(a => ({ ...a.account, platform: a.platform }));
    const selectedAccountIds = selectedAccounts.reduce((a, b) => ({ ...a, [b.platform]: b.account?.id }), {});

    onSelectionChange(selectedData);
    setStateIntegrate(selectedAccountIds);
  };


  const onChangeHandler = (e: any) => {
    setValSearch(e.target.value);
  };

  const handleSearch = () => {
    setLoading(true);
    let params: paramsAdditional = {
      email: '',
      phone: valSearch,
    };
    dispatch(setParamsAditional({ email: '', phone: valSearch }));
    if (valSearch.includes('@')) {
      dispatch(setParamsAditional({ email: valSearch, phone: '' }));
      params = {
        email: valSearch,
        phone: '',
      };
    }
    dispatch(getSearchAddttional({ email: params.email, phone: params.phone }))
      .unwrap()
      .then((res: any) => {
        setLoading(false);
        if (res?.bluelink.length || res?.ctb.length || res?.myhyundai.length) {
          dispatch(setAdditionalBy(valSearch));
          setModal(false);
          setModalAdditionalAccount();
        }
      }).catch((err: any) => {
        setLoading(false);
      });
  };

  return (
    <div className="flex flex-col items-center">
      <div className="flex flex-col items-start md:w-[480px]">
        <strong> {LocalizedString.idMigration.infoFoundSomeEmails} {localStorage.getItem('currentEmail')} {LocalizedString.idMigration.infoAndMobilePhone} {localStorage.getItem('codePhone')}{localStorage.getItem('currentPhoneNumber')} </strong>
        <div className="text-xs">
          {LocalizedString.idMigration.infoPleaseVerifyAdditionalAccount}
        </div>
        <div className="bg-cyan-50 border-cyan-200 border rounded-b text-cyan-900 px-3 py-3 mt-4 mb-4" role="alert">
          <div className="flex items-center">
            <div className="py-1">
              <svg
                className="fill-current h-6 w-6 bg-cyan-500 text-white rounded-full mr-2"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
              >
                <path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z" />
              </svg>
            </div>
            <div>
              <p className="text-xs">
                {LocalizedString.idMigration.infoLetsHighlightOneId}
              </p>
            </div>
          </div>
        </div>
        {data.map((item, index) => (
          <div className="bg-white mb-[18px] w-full" key={index}>
            <div className="text-neutral-500 pt-4 pl-4 text-xs">{LocalizedString.idMigration.labelPlatform}</div>
            <h1 className="text-primary pl-4 text-lg mb-2">{item.platform}</h1>
            {item.account.length === 0 ? (
              <div className="text-sm p-4">{LocalizedString.idMigration.infoAccountPlatformNotFound}</div>
            ) : (
              item.account.map((acc, index) => (
                <PlatformAcount
                  key={index}
                  clickHandleOptionChange={handleOptionChange}
                  platform={item.platform}
                  selectedAccountId={stateIntegrate[item.platform]}
                  data={acc}
                />
              ))
            )}
          </div>
        ))}
        {isAbleToSearch && localStorage.getItem('disableSearch') !== '1' && <>
          <div className="mb-4">{LocalizedString.idMigration.infoDoYouUserEmailForOtherPlatforms}</div>
          <div className="bg-secondary text-center w-full" onClick={() => setModal(true)}>
            <button className="py-2 text-white">
              <Icon icon="search" size={20} />
              {LocalizedString.idMigration.labelSearchAdditionalAccount}
            </button>
          </div>
        </>}
      </div>
      <ModalSearch
        show={modal}
        value={valSearch}
        onChange={onChangeHandler}
        onCloseModal={() => setModal(false)}
        onConfirm={handleSearch}
        loading={loading}
      />
    </div>
  );
};

export default IntegrateAccount;
