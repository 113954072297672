import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { alertMessage } from '../helpers/helper';
import { initialStateProps, paramsAdditional, payloadOtpFindId, payloadValidateOtpFIndId } from '../helpers/types';
import api from '../service/api';

export const onValidateOtp: any = createAsyncThunk('onValidateOtp', async (data, { dispatch, rejectWithValue }) => {
  try {
    dispatch(setLoading(true));
    const response: any = await api.validateOtp(data);
    dispatch(setLoading(false));
    return response.data;
  } catch (error) {
    dispatch(setLoading(false));
    console.log(error);
    return rejectWithValue(error);
  }
});

export const onSendOtp: any = createAsyncThunk('onSendOtp', async (data, { rejectWithValue }) => {
  try {
    const response: any = await api.sendOtp(data);
    return response.data;
  } catch (error) {
    console.log(error);
    rejectWithValue(error);
  }
});

export const onSendEmailOtp: any = createAsyncThunk('onSendEmailOtp', async (data, { rejectWithValue }) => {
  try {
    const response: any = await api.sendOtp(data);
    return response.data;
  } catch (error) {
    console.log(error);
    rejectWithValue(error);
  }
});

export const onGetUserList: any = createAsyncThunk('onGetUserList', async (data, { rejectWithValue }) => {
  try {
    const response: any = await api.getUserList(data);
    return response.data;
  } catch (error) {
    console.log(error);
    rejectWithValue(error);
  }
});

export const onGetUserData: any = createAsyncThunk('onGetUserData', async (data, { rejectWithValue }) => {
  try {
    const response: any = await api.getUserData(data);
    return response.data;
  } catch (error) {
    console.log(error);
    rejectWithValue(error);
  }
});

export const doSubmit: any = createAsyncThunk('doSubmit', async (data, { rejectWithValue }) => {
  try {
    const response: any = await api.doSubmit(data);
    return response.data;
  } catch (error) {
    console.log(error);
    rejectWithValue(error);
  }
});

export const getSearchAddttional: any = createAsyncThunk(
  'getSearchAddttional',
  async (params: paramsAdditional, { rejectWithValue }) => {
    try {
      const response: any = await api.getSearchAddttional({ phone: params.phone, email: params.email });
      return response.data;
    } catch (error: any) {
      alertMessage.error(error.response.data.Message);
      rejectWithValue(error);
    }
  }
);

export const getInfouser: any = createAsyncThunk(
  'getInfouser',
  async (params: paramsAdditional, { rejectWithValue }) => {
    try {
      const response: any = await api.getInfouser();
      return response.data;
    } catch (error) {
      console.log(error);
      rejectWithValue(error);
    }
  }
);

export const updateIsChoose: any = createAsyncThunk('updateIsChoose', async (data, { rejectWithValue }) => {
  try {
    const response: any = await api.updateIsChoose(data);
    return response.data;
  } catch (error) {
    console.log(error);
    rejectWithValue(error);
  }
});

export const submitMigration: any = createAsyncThunk('submitMigration', async (data, { rejectWithValue }) => {
  try {
    const response: any = await api.submitMigration(data);
    return response.data;
  } catch (error) {
    return error;
  }
});

export const submitSearchAdditional: any = createAsyncThunk(
  'submitSearchAdditional',
  async (data, { rejectWithValue }) => {
    try {
      const response: any = await api.submitSearchAdditional(data);
      return response.data;
    } catch (error) {
      console.log(error);
      rejectWithValue(error);
    }
  }
);

export const otpFindId: any = createAsyncThunk('otpFindId', async (phone: string, { dispatch, rejectWithValue }) => {
  try {
    dispatch(setLoading(true));
    const payload: payloadOtpFindId = {
      PHONENUMBER: phone,
      REQUESTUSING: 'PHONE',
    };
    const response: any = await api.otpFindId(payload);
    dispatch(setLoading(false));
    return response.data;
  } catch (error: any) {
    dispatch(setLoading(false));
    const message = error?.response?.data?.Message?.split('|')[0];
    alertMessage.error(message);
    rejectWithValue(error);
  }
});

export const getCountryCode: any = createAsyncThunk('getCountryCode', async (data, { rejectWithValue, dispatch }) => {
  try {
    const response: any = await api.getCountryCode();
    const findIndex = response?.data?.findIndex((item: any) => item.phoneExt === '+62');
    dispatch(setIndexCountryCode(findIndex));
    const temp = response?.data?.map((res: any) => {
      return {
        label: res.phoneExt,
        value: res.phoneExt,
      };
    });
    return temp;
  } catch (error: any) {
    const message = error?.response?.data?.Message?.split('|')[0];
    alertMessage.error(message);
    rejectWithValue(error);
  }
});

export const validateOtpFindId: any = createAsyncThunk(
  'validateOtpFindId',
  async (payload: payloadValidateOtpFIndId, { dispatch, rejectWithValue }) => {
    try {
      dispatch(setLoading(true));
      const response: any = await api.validateOtpFindId(payload);
      dispatch(setNavigateToSucces(true));
      dispatch(setLoading(false));
      return response.data;
    } catch (error: any) {
      dispatch(setLoading(false));
      return error;
    }
  }
);

export const resendOtpFindId: any = createAsyncThunk(
  'resendOtpFindId',
  async (key: string, { dispatch, rejectWithValue }) => {
    try {
      dispatch(setLoading(true));
      const response: any = await api.resendOtpFindId(key);
      dispatch(setLoading(false));
      return response.data;
    } catch (error: any) {
      dispatch(setLoading(false));
      const message = error?.response?.data?.Message?.split('|')[0];
      alertMessage.error(message);
      rejectWithValue(error);
    }
  }
);

export const doGetUrlLogin: any = createAsyncThunk(
  'doGetUrlLogin',
  async (url: string, { dispatch, rejectWithValue }) => {
    try {
      dispatch(setLoading(true));
      const response: any = await api.doGetUrlLogin(url);
      dispatch(setLoading(false));
      return response.data;
    } catch (error: any) {
      dispatch(setLoading(false));
      const message = error?.response?.data?.Message?.split('|')[0];
      alertMessage.error(message);
      rejectWithValue(error);
    }
  }
);


export const getPrivacyPolicy: any = createAsyncThunk(
  'getPrivacyPolicy',
  async (url: string, { dispatch, rejectWithValue }) => {
    try {
      dispatch(setLoading(true));
      const response: any = await api.getPrivacyPolicy();
      dispatch(setLoading(false));
      return response.data;
    } catch (error: any) {
      dispatch(setLoading(false));
      const message = error?.response?.data?.Message?.split('|')[0];
      alertMessage.error(message);
      rejectWithValue(error);
    }
  }
);
const initialState: initialStateProps = {
  data: [],
  otp: {},
  account: {},
  accounts: [],
  chosenId: [],
  listSearchAddtional: {
    myhyundai: [],
    ctb: [],
    bluelink: [],
  },
  additonalBy: '',
  otpResponse: {
    key: '',
    id: 0,
    minPinResendIntervalMinutes: 0,
    validUntil: '',
  },
  paramsAditional: {
    phone: '',
    email: '',
  },
  contryCode: [],
  indexCountryCode: 0,
  resOtpFindId: {
    id: 0,
    key: '',
  },
  resValidateFindID: [],
  navigateToSucces: false,
  loading: false,
  seconds: 59,
  isAbleToSearch: true,
  additionalAccount: {
    myhyundai: [],
    ctb: [],
    bluelink: [],
  },
  selectAdditional: [],
  privacyPolicy: ""
};

const ssoSlice = createSlice({
  name: 'ssoSlice',
  initialState: { ...initialState },
  reducers: {
    setData: (state, action) => {
      state.data = action.payload;
    },
    setAccountData: (state, action) => {
      state.account = action.payload;
    },
    setChosenId: (state, action) => {
      state.chosenId = action.payload;
    },
    setAccountList: (state, action) => {
      state.accounts = action.payload;
    },
    setAdditionalBy: (state, action) => {
      state.additonalBy = action.payload;
    },
    setParamsAditional: (state, action) => {
      state.paramsAditional.email = action.payload.email;
      state.paramsAditional.phone = action.payload.phone;
    },
    setIndexCountryCode: (state, action) => {
      state.indexCountryCode = action.payload;
    },
    setNavigateToSucces: (state, action) => {
      state.navigateToSucces = action.payload;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setIsAbleToSearch: (state, action) => {
      state.isAbleToSearch = action.payload;
    },
    setAddtionalAccountMyhyundai: (state, action) => {
      state.additionalAccount.myhyundai = action.payload;
    },
    setAddtionalAccountCtb: (state, action) => {
      state.additionalAccount.ctb = action.payload;
    },
    setAddtionalAccountBlueLink: (state, action) => {
      state.additionalAccount.bluelink = action.payload;
    },
    setSelcetAdditional: (state, action) => {
      state.selectAdditional = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(onSendOtp.fulfilled, (state, action) => {
      state.otp = action.payload;
      state.seconds = 59;
    });
    builder.addCase(getSearchAddttional.fulfilled, (state, action) => {
      if (action.payload.ctb) {
        state.listSearchAddtional.ctb = action.payload.ctb;
      }
      if (action.payload.myhyundai) {
        state.listSearchAddtional.myhyundai = action.payload.myhyundai;
      }
      if (action.payload.bluelink) {
        state.listSearchAddtional.bluelink = action.payload.bluelink;
      }
    });
    builder.addCase(onSendEmailOtp.fulfilled, (state, action) => {
      state.otpResponse = action.payload;
    });
    builder.addCase(getCountryCode.fulfilled, (state, action) => {
      state.contryCode = action.payload;
    });
    builder.addCase(otpFindId.fulfilled, (state, action) => {
      state.resOtpFindId.id = action.payload.id;
      state.resOtpFindId.key = action.payload.key;
    });
    builder.addCase(validateOtpFindId.fulfilled, (state, action) => {
      state.resValidateFindID = action.payload;
    });
    builder.addCase(getPrivacyPolicy.fulfilled, (state, action) => {
      state.privacyPolicy = action.payload.body;
    });
  },
});

export const getData = (state: any) => state.data;
export const getOtp = (state: any) => state.otp;
export const getAccount = (state: any) => state.account;
export const getAccountList = (state: any) => state.accounts;
export const getChosenId = (state: any) => state.chosenId;
export const getSeconds = (state: any) => state.seconds;

export const {
  setAccountData,
  setAccountList,
  setChosenId,
  setAdditionalBy,
  setParamsAditional,
  setIndexCountryCode,
  setNavigateToSucces,
  setLoading,
  setIsAbleToSearch,
  setAddtionalAccountMyhyundai,
  setAddtionalAccountCtb,
  setAddtionalAccountBlueLink,
  setSelcetAdditional
} = ssoSlice.actions;
export default ssoSlice.reducer;
