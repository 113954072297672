import { FC, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { onGetUserList, onSendOtp, onValidateOtp, setAccountList } from '../../ssoSlicer/ssoSlice';
import ModalOtp from '../modal/ModalOtp/ModalOtp';
import { alertMessage } from '../../helpers/helper';
import { LocalizedString } from '../../shared/localization';


interface PlatformAcountProps {
  clickHandleOptionChange: (platform: string, e: any) => void;
  platform: any;
  selectedAccountId?: number;
  data: any;
}

const PlatformAcount: FC<PlatformAcountProps> = ({platform, selectedAccountId, data, clickHandleOptionChange}) => {
  const dispatch = useDispatch();
  const [isModal, setModal] = useState(false);
  // const [selectedAccount, setSelectedAccount] = useState(false);
  const [loading, setLoading] = useState(false);
  const [stateEmail, setStateEmail] = useState('');
  const [account, setAccount] = useState({ ...data, loading: false });

  const [otpResponse, setOtpResponse] = useState({
    id: null,
    key: null,
    OTP: null,
    PhoneNumber: null,
    Email: null,
    RequestUsing: 'email',
    Application: '',
    OtpFrom: 'CtbMyIDM',
  });

  const displayModal = (account: any) => {
    setStateEmail(account.email);
    // setSelectedAccount(account);
    SendOTP(account);
  };

  const SendOTP = (account: any) => {
    setAccount({ ...account, loading: true });
    const payload = {
      EMAIL: account.email,
      REQUESTUSING: 'EMAIL',
      KEY: account.key,
    };
    dispatch(onSendOtp(payload))
      .unwrap()
      .then((res: any) => {
        setAccount({ ...account, loading: false });
        if(res?.status && (res?.status === 400 || res?.status === 500)){
          const message = res?.message;
          alertMessage.error(message);
          return;
        }
        setOtpResponse(res);
        setModal(true);
      })
      .catch((err: any) => {
        setAccount({ ...account, loading: false });
      });
  };

  const ReSendOTP = () => {
    setAccount({ ...account, loading: true });
    const payload = {
      EMAIL: account.email,
      REQUESTUSING: 'EMAIL',
      KEY: account.key,
    };
    dispatch(onSendOtp(payload))
      .unwrap()
      .then((res: any) => {

        setAccount({ ...account, loading: false });
        if(res?.status && (res?.status === 400 || res?.status === 500)){
          const message = res?.message;
          alertMessage.error(message);
          return;
        }
        setOtpResponse(res);
      })
      .catch((err: any) => {
        setAccount({ ...account, loading: false });
      });
  };

  const ValidateOTP = (code: any) => {
    setLoading(true);

    const payload = {
      IdOtpSSO: otpResponse.id,
      Key: otpResponse.key,
      OTP: code,
      PhoneNumber: '',
      Email: stateEmail,
      RequestUsing: 'email', //email atau phone
      Application: '',
      OtpFrom: 'CtbMyIDM', //SearchAddAccount atau BluelinkIDM atau CtbMyIDM
    };
    dispatch(onValidateOtp(payload))
      .unwrap()
      .then((res: any) => {
        if (res) {
          setLoading(false);
          setModal(false);
          GetUserLists(localStorage.getItem('currentPhoneNumber'), localStorage.getItem('currentEmail'));
        }
      })
      .catch((err: any) => {
        setLoading(false);
        if (err?.response?.status === 400 || err?.response?.status === 500) {
          const message = err?.response?.data?.Message.split('|')[0];
          alertMessage.error(message);
          return;
        }
      });
  };

  const HandleOptionChange = (e: any) => {
    clickHandleOptionChange(platform, e.target.value);
  };

  const GetUserLists = (phone: any, email: any) => {
    setLoading(true);

    const payload = {
      "email" : email,
      "phoneNumber": phone,
      "source": "CtbMyIDM",
    }
    dispatch(onGetUserList(payload))
      .unwrap()
      .then((res: any) => {
        if (res) {
          localStorage.setItem('idMigration', res.idMigration);
          const {bluelink, ctb, myhyundai} = res;
          const data = [
            {
              platform: 'Bluelink',
              account: bluelink
            },
            {
              platform: 'myHyundai',
              account: myhyundai
            },
            {
              platform: 'Clicktobuy',
              account: ctb,
            },
          ];

          if(bluelink.length){
            let idButtonDisabled = '0';
            let inMyHyundai = false;
            let inCtb = false;
            for (let i = 0; i < bluelink.length; i++) {
              const val = bluelink[i];
              if(myhyundai.length){
                const myhData = myhyundai.filter((v: any) => v.email === val.email);
                if(myhData.length > -1) {
                  inMyHyundai = true
                }else{
                  inMyHyundai = false
                }
              }
              if(ctb.length){
                const ctbData = ctb.filter((v: any) => v.email === val.email);
                if(ctbData.length > -1) {
                  inCtb = true
                }else{
                  inCtb = false
                }
              }
            }
            idButtonDisabled = (inMyHyundai && inCtb ? '1': '0')
            localStorage.setItem('disableSearch', idButtonDisabled);
          }else{
            localStorage.setItem('disableSearch', '0');
          }
          dispatch(setAccountList(data));
        }
      })
      .catch((err: any) => console.log(err, "ini rerorr"));
  }

  useEffect(() => {
    setAccount({ ...data, loading: false })
  }, [data]);

  return (
    <>
      <div className="border-b-[1px] border-b-background" key={account.id}>
        <label className="bg-white flex hover:bg-slate-100 hover:cursor-pointer ">
          <div className="text-sm flex-grow p-4">
            {account.email}
            {account.isVerify ? (
              <div className="bg-secondary text-white w-1/4 text-xs text-center py-1 mt-1">{LocalizedString.idMigration.labelVerified}</div>
            ) : (
              <div className="bg-danger text-white w-1/3 text-xs text-center py-1 mt-1 whitespace-nowrap">
                {LocalizedString.idMigration.labelNotVerified}
              </div>
            )}
          </div>
          <div className="self-center pr-4">
            <input
              className={
                account.isVerify
                  ? 'w-5 h-5 text-secondary border-secondary focus:ring-0 dark:focus:ring-0'
                  : 'w-5 h-5 focus:ring-0 dark:focus:ring-0'
              }
              type="radio"
              name={`radioAccount_${platform}`}
              id={account.id}
              checked={account.id == selectedAccountId}
              disabled={account.isVerify ? false : true}
              value={account.id}
              onChange={HandleOptionChange}
            />
          </div>
        </label>
        {!account.isVerify && (
          <div className="px-4 mb-4">
            <button
              disabled={account.loading}
              className="text-primary border-2 border-primary text-center py-2 w-full"
              onClick={() => displayModal(account)}
            >
              {account.loading ? LocalizedString.idMigration.labelLoadingPleaseWait : LocalizedString.idMigration.labelBtnSendVerificationCode}
            </button>
          </div>
        )}
      </div>

      {isModal && <ModalOtp
        show={isModal}
        // onCancel={() => setShowModalOtp(false)}
        title={LocalizedString.idMigration.titleOtpCode}
        desc={`${LocalizedString.idMigration.descEmailA} ${stateEmail} ${LocalizedString.idMigration.descEmailB}`}
        onCloseModal={() => setModal(false)}
        onConfirm={(code) => ValidateOTP(code)}
        onResendOtp={()=>ReSendOTP()}
        btnTextConfirm={LocalizedString.idMigration.btnOk}
        loading={loading}
      />}
    </>
  );
};

export default PlatformAcount;
