import LocalizedStrings from 'localized-strings';
// import { statePhoneWithFormat } from '';

export const LocalizedString = new LocalizedStrings({
  'en-US': {
    idMigration: {
      title: 'ID Migration Process',
      labelSearchYourAccount: 'Search Your Account',
      labelSearchResults: 'Search Results',
      labelChooseIDToUse: 'Choose ID to Use',
      errMessageNext: 'Please select an account',
      loadingDesc: 'Please wait, waiting for the process to be finished...',
      titleCancelIdMigration: 'Cancel Integration Process',
      descCancelIdMigration: 'Are you sure to cancel all process Hyundai One ID?',
      labelYesCancelIdMigration: 'Yes',
      labelNoCancelIdMigration: 'No, Continue',
      labelHi: 'Hi,',
      labeldescIdMigration:
        'We will connect your all hyundai accounts to become one, please input your valid phone number to start migration.',
      labelPhoneNumber: 'Phone Number',
      labelBtnPleaseWait: 'Please wait',
      labelBtnContinue: 'Continue',
      titleOtpCode: 'OTP Code Verification',
      // descOtpCode: `A code is sent to your phone number ${statePhoneWithFormat}. Please check your inbox and copy the OTP Code here.`,
      descOtpCodeA: `A code is sent to your phone number`,
      descOtpCodeB: `. Please check your inbox and copy the OTP Code here.`,
      btnVerifyOtp: 'Verify OTP',
      labelOtpExpiredIn: 'OTP expired in',
      labelMin: 'min',
      labelSecond: 'second',
      textDidntReceiveCode: `Didn't recieve code?`,
      textResendAnOtpCodeIn: 'Resend an OTP Code in',
      btnResendOtp: 'Resend OTP',
      labelPleaseWait: 'Please Wait',
      labelVerifyOtp: 'Verify OTP',
      labelWelcomeTo: 'Welcome to',
      labelHyundaiOneId: 'Hyundai One ID',
      labelSignIn: 'Sign In',
      descSignIn: 'Sign in using your email, you can also use "Bluelink" or "Click To Buy" account to continue.',
      placeholderEmailAddress: 'Email Address',
      labelLoadingPleaseWait: 'Please wait...',
      btnFindID: 'Find ID',
      labelNotMember: 'Not a member?',
      btnRegister: 'Register',
      labelForgotYourId: 'Forgot your ID?',
      descForgotYourId: 'Please filled the form below to get your ID.',
      labelPhoneNumberMandatory: 'Phone Number *',
      labelVerificationCodeMust: 'Verification code must be filled in 5 minutes.',
      labelNotYetReceiveVerificationCode:
        'If your not yet receive verification code or get limit time, please click “Resend Code”.',
      labelBtnSendVerificationCode: 'Send Verification Code',
      labelResendCodeIn: `Resend Code In`,
      placeholderVerificationCode: 'Verification Code',
      labelBtnSubmit: 'Submit',
      labelSuccess: 'Success',
      descPleaseFindYourID: 'Please find your ID to access your account.',
      btnGotoLogin: 'Go To Login',
      errUserWasNotFound: 'The user was not found',
      infoFoundSomeEmails: 'We found some emails that use email:',
      infoAndMobilePhone: 'and mobile phone:',
      infoPleaseVerifyAdditionalAccount:
        'Please verify additional account to integrate. You can choose one email ID per platform.',
      infoLetsHighlightOneId: `Let's highlight "one ID" per platform. All of the benefits from each myHyundai ID you verify will be combined with your Hyundai One ID.`,
      labelPlatform: 'Platform',
      infoAccountPlatformNotFound: 'Account for this platform not found!',
      infoDoYouUserEmailForOtherPlatforms: 'Do you use email for other platforms?',
      labelSearchAdditionalAccount: 'Search Additional Account',
      labelVerified: 'Verified',
      titleDeleteEmailID: 'Delete Email ID',
      descDeleteEmailID: 'Are you sure to delete additional account?',
      btnNo: 'No',
      btnYesDelete: 'Yes, Delete',
      labelAddAdditionalAccount: 'Add Additional Account',
      titleAddAccountConfirmation: 'Add Account Confirmation',
      descAddAccountConfirmation: 'Are you sure to continue add account?',
      btnYesAddAccount: 'Yes, Add Account',
      errFailedToAddYourAccount: 'Failed to add your account',
      titleCancelAddingAccount: 'Cancel Adding Account',
      descCancelAddingAccount: 'Are you sure to cancel searching for additional accounts?',
      btnYesCancel: 'Yes, Cancel',
      labelVerificationSucceded: 'Verification Succeded',
      labelPLeaseChooseAccountsToIntegrate: 'Please choose accounts to integrate.',
      labelMyHyundai: 'myHyundai',
      labelClickToBuy: 'Click To Buy',
      labelBluelink: 'Bluelink',
      labelCancelAdditionalAccount: 'Cancel Additional Account',
      labelAddSearchedAccount: 'Add the Searched Account',
      labelNotVerified: 'Not Verified',
      descEmailA: `A code is sent to your email`,
      descEmailB: `. Please check your inbox and copy the OTP Code here.`,
      btnOk: 'OK',
      labelInsertYourEmailOrPhone: 'Insert your email or phone number to search',
      placeholderEmailOrPhoneNumber: 'Email or Phone Number',
      labelSearch: 'Search',
      labelPleaseChooseRepresentativeEmailForLogin: `Please choose a representative e-mail to use for log-in. This e-mail address will be your ID for all Hyundai's digital platforms - myHyundai / Bluelink / Click to Buy. Also, all service data under the following e-mail addresses will be automatically connected to your ID.`,
      labelYouCanAlsoUseIDFromPlatformCreateHyundaiOneID:
        'You can also use an ID from one Platform to create Hyundai One ID. This process will only be conducted once and you will not be required to redo this process again.',
      labelCongratulations: 'Congratulations',
      labelYouHaveFinishedIDMigrationProcess: 'You have finished the ID Migration process',
      labelYears: 'years',
      labelYear: 'year',
      labelMonth: 'month',
      labelDay: 'day',
      labelMakeAProfile: 'Make a Profile',
      labelYouAreCurrentlyMigratingBluelinkID:
        'You are currently migrating a Bluelink ID. You will be able to change your profile information later after the migration process.',
      labelFullName: 'Full Name',
      placeholderYourFullName: 'Your Full Name',
      errYourNameIsRequired: 'Your Name is Required',
      placeholderDOB: 'DOB',
      labelDateOfBirth: 'Date of Birth',
      placeholderSelectADate: 'Select a date',
      placeholderPhoneNumber: 'Phone Number',
      errPhoneNumberRequired: 'Your Phone Number is Required',
      labelEmail: 'Email',
      placeholderEmail: 'Email',
      labelCreateNewPassword: 'Create New Password *',
      placeholderPassword: 'Password',
      labelText: 'text',
      infoUppercaseLowercaseNumberSpecialCharacter: 'Min 8 characters, including 1 number',
      labelConfirmPassword: 'Confirm Password *',
      placeholderConfirmPassword: 'Confirm Password',
      labelPasswordDoesntMatch: `Password doesn't match`,
      labelPIN: 'PIN *',
      placeholderPIN: 'PIN - 4 digits number',
      labelInput4DigitsPIN: 'Input 4 digits PIN',
      labelConfirmPIN: 'Confirm PIN *',
      placeholderConfirmPIN: 'Confirm PIN',
      errConfirmPIN: `PIN doesn't match`,
      labelIAgreeTo: 'I agree to',
      labelPrivacyPolicy: 'Privacy Policy',
      labelMyHundaiIndonesia: 'myHyundai Indonesia',
      labelWeFoundSomeAccountsThatUsingEmail: 'We found some accounts that using email',
      labelPleaseVerifyAdditionalAccountToIntegrate: 'Please verify additional account to integrate',
      titlePrivacyPolicy: 'Privacy Policy',
      descPin: 'This PIN is used for transactional activities',
      loadingHyundaiOneId: 'Please Wait...',
    },
    help: {
      customerCare: 'Customer Care',
      hyundai: 'PT Hyundai Motors Indonesia',
      directorate: 'Directorate General of Consumer Protection and Trade Compliance',
      ministry: 'Ministry of Trade of The Republic of Indonesia',
      phone: 'Phone',
      email: 'Email',
      whatsapp: 'WhatsApp',
    },
  },
  ID: {
    idMigration: {
      title: 'Proses Migrasi ID',
      labelSearchYourAccount: 'Cari Akun Anda',
      labelSearchResults: 'Hasil Pencarian',
      labelChooseIDToUse: 'Pilih ID untuk Digunakan',
      errMessageNext: 'Silakan pilih akun',
      loadingDesc: 'Harap tunggu, menunggu proses selesai...',
      titleCancelIdMigration: 'Batalkan Proses Integrasi',
      descCancelIdMigration: 'Apakah Anda yakin akan membatalkan semua proses Hyundai One ID?',
      labelYesCancelIdMigration: 'Ya',
      labelNoCancelIdMigration: 'Tidak, lanjutkan',
      labelHi: 'Hai,',
      labeldescIdMigration:
        'Kami akan menghubungkan semua akun Hyundai Anda menjadi satu, silakan masukkan nomor telepon Anda yang valid untuk memulai migrasi.',
      labelPhoneNumber: 'Nomor telepon',
      labelBtnPleaseWait: 'Harap tunggu',
      labelBtnContinue: 'Melanjutkan',
      titleOtpCode: 'Verifikasi kode OTP',
      // descOtpCode: `Kode dikirim ke nomor telepon Anda $ {statePhoneWithFormat}.Silakan periksa kotak masuk Anda dan salin kode OTP di sini.`,
      descOtpCodeA: `Kode dikirim ke nomor telepon Anda`,
      descOtpCodeB: `. Silakan periksa kotak masuk Anda dan salin kode OTP disini.`,
      btnVerifyOtp: 'Verifikasi OTP',
      labelOtpExpiredIn: 'OTP kedaluwarsa dalam',
      labelMin: 'min',
      labelSecond: 'kedua',
      textDidntReceiveCode: `Tidak menerima kode?`,
      textResendAnOtpCodeIn: 'Kirimkan kembali kode OTP di',
      btnResendOtp: 'Kirim Ulang OTP',
      labelPleaseWait: 'Harap Tunggu',
      labelVerifyOtp: 'Verifikasi OTP',
      labelWelcomeTo: 'Selamat Datang di',
      labelHyundaiOneId: 'Hyundai One ID',
      labelSignIn: 'Masuk',
      descSignIn: 'Masuk menggunakan email Anda, Anda juga dapat menggunakan akun "Bluelink" atau "Click To Buy" untuk melanjutkan.',
      placeholderEmailAddress: 'Alamat email',
      labelLoadingPleaseWait: 'Harap tunggu...',
      btnFindID: 'Temukan ID',
      labelNotMember: 'Bukan anggota?',
      btnRegister: 'Daftar',
      labelForgotYourId: 'Lupa ID Anda?',
      descForgotYourId: 'Harap isi formulir dibawah ini untuk mendapatkan ID Anda.',
      labelPhoneNumberMandatory: 'Nomor Telepon *',
      labelVerificationCodeMust: 'Kode verifikasi harus diisi dalam 5 menit.',
      labelNotYetReceiveVerificationCode:
        'Jika Anda belum menerima kode verifikasi atau mencapai batas waktu, silakan klik “Kirim Ulang Kode”.',
      labelBtnSendVerificationCode: 'Kirim Kode Verifikasi',
      labelResendCodeIn: `Kirim Ulang Kode Di`,
      placeholderVerificationCode: 'Kode Verifikasi',
      labelBtnSubmit: 'Kirim',
      labelSuccess: 'Berhasil',
      descPleaseFindYourID: 'Silakan temukan ID Anda untuk mengakses akun Anda.',
      btnGotoLogin: 'Pergi ke Login',
      errUserWasNotFound: 'Pengguna tidak ditemukan',
      infoFoundSomeEmails: 'Kami menemukan beberapa email yang menggunakan email:',
      infoAndMobilePhone: 'dan nomor ponsel:',
      infoPleaseVerifyAdditionalAccount:
        'Harap verifikasi akun tambahan untuk diintegrasikan. Anda dapat memilih satu ID email per platform.',
      infoLetsHighlightOneId: `Mari menyorot "One ID" per platform. Semua manfaat dari setiap ID myHyundai yang Anda verifikasi akan dikombinasikan dengan Hyundai One ID Anda.`,
      labelPlatform: 'Platform',
      infoAccountPlatformNotFound: 'Akun untuk platform ini tidak ditemukan!',
      infoDoYouUserEmailForOtherPlatforms: 'Apakah Anda menggunakan email untuk platform lain?',
      labelSearchAdditionalAccount: 'Cari Akun Tambahan',
      labelVerified: 'Diverifikasi',
      titleDeleteEmailID: 'Hapus ID Email',
      descDeleteEmailID: 'Apakah Anda yakin akan menghapus akun tambahan?',
      btnNo: 'TIDAK',
      btnYesDelete: 'Ya, Hapus',
      labelAddAdditionalAccount: 'Tambah Akun Tambahan',
      titleAddAccountConfirmation: 'Konfirmasi Tambah Akun',
      descAddAccountConfirmation: 'Apakah Anda yakin akan menambahkan akun?',
      btnYesAddAccount: 'Ya, Tambah Akun',
      errFailedToAddYourAccount: 'Gagal menambahkan akun Anda',
      titleCancelAddingAccount: 'Batalkan Penambahan Akun',
      descCancelAddingAccount: 'Apakah Anda yakin akan membatalkan pencarian akun tambahan?',
      btnYesCancel: 'Ya, Batal',
      labelVerificationSucceded: 'Verifikasi berhasil',
      labelPLeaseChooseAccountsToIntegrate: 'Silakan pilih akun untuk diintegrasikan.',
      labelMyHyundai: 'myHyundai',
      labelClickToBuy: 'Click To Buy',
      labelBluelink: 'Bluelink',
      labelCancelAdditionalAccount: 'Batalkan Penambahan Akun',
      labelAddSearchedAccount: 'Tambah Akun yang Ditemukan',
      labelNotVerified: 'Tidak Terverifikasi',
      descEmailA: `Kode dikirim ke email Anda`,
      descEmailB: `. Silakan periksa kotak masuk Anda dan salin kode OTP disini.`,
      btnOk: 'OK',
      labelInsertYourEmailOrPhone: 'Masukkan email atau nomor telepon Anda untuk mencari',
      placeholderEmailOrPhoneNumber: 'Email atau nomor telepon',
      labelSearch: 'Cari',
      labelPleaseChooseRepresentativeEmailForLogin: `Harap pilih email yang representatif untuk digunakan untuk masuk. Alamat email ini akan menjadi ID Anda untuk semua platform digital Hyundai - myHyundai / Bluelink / Click to Buy. Juga, semua data layanan dibawah alamat email berikut akan secara otomatis terhubung ke ID Anda.`,
      labelYouCanAlsoUseIDFromPlatformCreateHyundaiOneID:
        'Anda juga dapat menggunakan ID dari satu platform untuk membuat Hyundai One ID. Proses ini hanya akan dilakukan sekali dan Anda tidak akan diminta untuk mengulang proses ini lagi.',
      labelCongratulations: 'Selamat',
      labelYouHaveFinishedIDMigrationProcess: 'Anda telah menyelesaikan proses migrasi ID',
      labelYears: 'tahun',
      labelYear: 'tahun',
      labelMonth: 'bulan',
      labelDay: 'hari',
      labelMakeAProfile: 'Buat Profil',
      labelYouAreCurrentlyMigratingBluelinkID:
        'Anda sedang melakukan migrasi ID Bluelink. Anda akan dapat mengubah informasi profil Anda nanti setelah proses migrasi.',
      labelFullName: 'Nama Lengkap',
      placeholderYourFullName: 'Nama Lengkap Anda',
      errYourNameIsRequired: 'Nama Anda Diperlukan',
      placeholderDOB: 'Tanggal Lahir',
      labelDateOfBirth: 'Tanggal Lahir',
      placeholderSelectADate: 'Pilih tanggal',
      placeholderPhoneNumber: 'Nomor Telepon',
      errPhoneNumberRequired: 'Nomor Ttelepon Anda Diperlukan',
      labelEmail: 'Email',
      placeholderEmail: 'Email',
      labelCreateNewPassword: 'Buat Kata Sandi Baru *',
      placeholderPassword: 'Kata Sandi',
      labelText: 'teks',
      infoUppercaseLowercaseNumberSpecialCharacter: 'Min 8 karakter, termasuk 1 angka',
      labelConfirmPassword: 'Konfirmasi Sandi *',
      placeholderConfirmPassword: 'Konfirmasi Sandi',
      labelPasswordDoesntMatch: `Kata sandi tidak cocok`,
      labelPIN: 'PIN *',
      placeholderPIN: 'PIN - 4 digit angka',
      labelInput4DigitsPIN: 'Input 4 digit PIN',
      labelConfirmPIN: 'Konfirmasi PIN *',
      placeholderConfirmPIN: 'Konfirmasi PIN',
      errConfirmPIN: `PIN tidak cocok`,
      labelIAgreeTo: 'Saya setuju',
      labelPrivacyPolicy: 'Kebijakan Privasi',
      labelMyHundaiIndonesia: 'myHyundai Indonesia',
      labelWeFoundSomeAccountsThatUsingEmail: 'Kami menemukan beberapa akun yang menggunakan email',
      labelPleaseVerifyAdditionalAccountToIntegrate: 'Harap verifikasi akun tambahan untuk mengintegrasikan',
      titlePrivacyPolicy: 'Kebijakan Privasi',
      descPin: 'PIN ini digunakan untuk aktivitas transaksional',
      loadingHyundaiOneId: 'Tunggu Sebentar...',
    },
    help: {
      customerCare: 'Layanan Pengaduan Konsumen',
      hyundai: 'PT Hyundai Motors Indonesia',
      directorate: 'Direktorat Jenderal Perlindungan Konsumen dan Tertib Niaga',
      ministry: 'Kementerian Perdagangan Republik Indonesia',
      phone: 'Telepon',
      email: 'Email',
      whatsapp: 'WhatsApp',
    },
  },
});
