import { FC } from "react";
import { LocalizedString } from "../../shared/localization";

interface PlatformProps {
    clickHandleOptionChange: (e: any) => void;
    item: any;
    // hasBluelink: boolean;
    enable?: boolean;
}
const Platform: FC<PlatformProps> = ({ item, clickHandleOptionChange, enable }) => {

    const HandleOptionChange = (e: any) => {
        if (e) {
            clickHandleOptionChange(e.target.value);
        }
    };

    return (
        <div className={`card border p-4 w-full ${enable ? 'bg-white' : 'bg-gray-100'}`} style={{ marginTop: -1 }} key={item.id}>
            <p className="text-gray-700 text-sm">{LocalizedString.idMigration.labelPlatform}</p>
            <h1 className="text-xl text-primary" style={{ marginTop: -3 }}>{item.platform}</h1>
            <label className="flex justify-between">
                <div className="my-3 flex flex-col justify-start items-start">
                    <span>{item.email}</span>
                    <span className="mt-1 inline-block py-1 px-2 bg-secondary text-white text-sm">{LocalizedString.idMigration.labelVerified}</span>
                </div>
                <div className="self-center pr-4">
                    <input
                        className={`w-5 h-5 text-secondary ${enable ? 'border-secondary' : 'border-gray-300'} focus:ring-0 dark:focus:ring-0`}
                        type="radio"
                        name={`radioAccount_choose`}
                        id={item.id}
                        value={item.id}
                        onChange={HandleOptionChange}
                        disabled={!enable}
                    />
                </div>
            </label>
        </div>
    );
};

export default Platform;
