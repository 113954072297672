import { FC } from 'react';
import Icon from '../../icon';
import './ModalInfo.css';

interface ModalInfoProps {
  onCloseModal: () => void;
  title: string;
  show: boolean;
  desc: string;
  btnTextCancel: string;
  btnTextConfirm: string;
  onCancel: () => void;
  onConfirm: () => void;
  isReverseBtn?: boolean;
}

const ModalInfo: FC<ModalInfoProps> = ({
  onCloseModal,
  title,
  show,
  desc,
  btnTextCancel,
  btnTextConfirm,
  onCancel,
  onConfirm,
  isReverseBtn = false,
}) => {
  if (!show) {
    return null;
  }
  return (
    <div className="fixed top-0 left-0 z-50 bg-[#000000cc] w-full h-full" onClick={onCloseModal}>
      <div className="flex flex-col wrapper-modal bg-white shadow-lg" onClick={(e) => e.stopPropagation()}>
        <div className="flex flex-col items-start p-[18px]  ">
          <div className="flex flex-row justify-end items-start self-stretch ">
            <p className="cursor-pointer" onClick={onCloseModal}>
              <Icon icon="close" size={20} />
            </p>
          </div>
          <div className="flex flex-col items-start self-stretch">
            <h4 className="text-xl text-black mb-2">{title}</h4>
            <h4 className="text-base text-black">{desc}</h4>
          </div>
        </div>
        <div className="flex flex-row items-center p-[18px] border-t-[1px] border-t-[#EBEBEB]">
          {isReverseBtn ? (
            <>
              <button
                onClick={onCancel}
                className="flex flex-col justify-center items-center text-base grow text-primary font-bold"
              >
                {btnTextCancel}
              </button>
              <button
                onClick={onConfirm}
                className="flex flex-col justify-center items-center text-primary text-base grow"
              >
                {btnTextConfirm}
              </button>
            </>
          ) : (
            <>
              <button onClick={onConfirm} className="flex flex-col justify-center items-center text-base grow">
                {btnTextConfirm}
              </button>
              <button
                onClick={onCancel}
                className="flex flex-col justify-center items-center text-base grow text-secondary font-bold"
              >
                {btnTextCancel}
              </button>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default ModalInfo;
