import { toast } from 'react-toastify';

export const alertMessage = {
  error: (message: string, duration?: number) => {
    toast.dismiss();
    toast.error(message);
  },
  succes: (message: string, duration: number) => {
    toast.dismiss();
    toast.success(message);
  },
  info: (message: string, duration: number) => {
    toast.dismiss();
    toast.info(message);
  },
};