import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import Icon from '../../icon';
import { useSelector } from 'react-redux';
import { getSeconds } from '../../../ssoSlicer/ssoSlice';
import moment from 'moment';
import { LocalizedString } from '../../../shared/localization';

interface ModalOtpProps {
  onCloseModal: () => void;
  title: string;
  show: boolean;
  desc: string;
  btnTextConfirm?: string;
  onResendOtp?: any;
  onConfirm: (otp: any) => void;
  loading: boolean;
}

let currentOtpIndex: number = 0;

const ModalOtp: FC<ModalOtpProps> = ({
  onCloseModal,
  title,
  show,
  desc,
  btnTextConfirm,
  onResendOtp,
  onConfirm,
  loading,
}) => {
  const [codeOtp, setCodeOtp] = useState<string>('');
  const [focus, setFocus] = useState<boolean>(false);
  const inputRef = useRef<HTMLInputElement>(null);
  const [otp, setOtp] = useState<string[]>(new Array(6).fill(''));
  const [activeOtpIndex, setActiveOtpIndex] = useState<number>(0);
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);
  const [isPaste, setIsPaste] = useState(false);

  const focusHandler = () => {
    inputRef?.current?.focus();
  };

  const resetTimer = useCallback(() => {
    setOtp(new Array(6).fill(''));
    setMinutes(0);
    setSeconds(59);
    focusHandler();
    setTargetTime(moment().add(5, 'minutes'));
    setIsExpired(false);
  }, []);

  useEffect(() => {
    focusHandler();
  }, [activeOtpIndex]);

  useEffect(() => {
    resetTimer();
  }, []);

  const [targetTime, setTargetTime] = useState(moment().add(5, 'minutes'));
  const [currentTime, setCurrentTime] = useState(moment());
  const [isExpired, setIsExpired] = useState(false);
  const timeBetween = moment.duration(targetTime.diff(currentTime));

  useEffect(() => {
    if (!isExpired) {
      const interval = setInterval(() => {
        setCurrentTime(moment());
        if (currentTime > targetTime) {
          setIsExpired(true);
        }
      }, 1000);

      return () => clearInterval(interval);
    }
  }, [currentTime, isExpired, targetTime]);

  useEffect(() => {
    if (seconds > 0) {
      const interval = setInterval(() => {
        if (seconds > 0) {
          setSeconds(seconds - 1);
        }
      }, 1000);

      return () => {
        clearInterval(interval);
      };
    }
  }, [minutes, seconds]);

  const resendOTP = () => {
    onResendOtp();
    resetTimer();
  };

  const onConfirmFunc = () => {
    const formatOtp = otp.join('');
    onConfirm(formatOtp);
  };

  if (!show) {
    return null;
  }

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const { value } = e.target;
    const re = /^[0-9\b]+$/;
    if (value === '' || re.test(value)) {
      const newOtp: string[] = [...otp];
      newOtp[currentOtpIndex] = value.substring(value.length - 1);
      if (!value) setActiveOtpIndex(currentOtpIndex > 1 ? currentOtpIndex - 1 : 0);
      else setActiveOtpIndex(currentOtpIndex < 5 ? currentOtpIndex + 1 : 5);
      setOtp(newOtp);
    }
    if (value.trim().length == 6 && activeOtpIndex == 0 && re.test(value)) {
      handleAutoPaste(value);
    }
  };

  const handleAutoPaste = (val: string) => {
    const newOTP = [...otp];
    for (let i = 0; i < val.length && i < 6; i++) {
      newOTP[i] = val[i];
    }
    handleFocus();
    setActiveOtpIndex(newOTP.length - 1);
    setOtp(newOTP);
  };

  const handleKeyDown = ({ key }: React.KeyboardEvent<HTMLInputElement>, index: number) => {
    currentOtpIndex = index;
    if (!isPaste) {
      if (key === 'Backspace') {
        // Pada saat user melakukan input, system akan trigger event "onKeyDown" & "onChange".
        // Namun ada rare case dimana system hanya trigger event "onKeyDown" saja
        // sehingga script pada event "onChange" tidak tereksekusi.
        // Solusinya, langsung update OTP pada event "onKeyDown".
        const newOtp: string[] = [...otp]
        newOtp.splice(index, 1, '')
        setOtp(newOtp)

        setActiveOtpIndex(currentOtpIndex > 1 ? currentOtpIndex - 1 : 0);
      }
      if (key === 'Tab') setActiveOtpIndex(currentOtpIndex < 5 ? currentOtpIndex + 1 : 5);
    }
  };

  const handleFocus = () => {
    inputRef?.current?.focus();
  };

  const handlePaste = (e: React.ClipboardEvent) => {
    e.preventDefault();
    const re = /^[0-9\b]+$/;
    const pastedData = e.clipboardData.getData('text');
    const pasteMobile = e.nativeEvent.clipboardData?.getData('text');
    if (pasteMobile && re.test(pasteMobile)) {
      const newOTP = [...otp];
      for (let i = 0; i < pasteMobile.length && i < 6; i++) {
        newOTP[i] = pasteMobile[i];
      }
      handleFocus();
      setActiveOtpIndex(newOTP.length - 1);
      setOtp(newOTP);
    }

    if (re.test(pastedData)) {
      const newOTP = [...otp];

      for (let i = 0; i < pastedData.length && i < 6; i++) {
        newOTP[i] = pastedData[i];
      }
      handleFocus();
      setActiveOtpIndex(newOTP.length - 1);
      setOtp(newOTP);
    }
  };

  return (
    <div className="justify-center bg-[#000000cc] items-center flex overflow-x-hidden overflow-y-auto fixed top-0 left-0 h-full inset-0 z-50 outline-none focus:outline-none">
      <div className="relative w-auto my-6 mx-4 max-w-3xl md:w-[400px] lg:w-[450px]">
        <div
          className="border-0 shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none  "
          onClick={(e) => e.stopPropagation()}
        >
          <div className="p-[14px] lg:p-[30px] md:w-[400px] lg:w-[450px]">
            <div className="flex flex-row justify-start items-start self-stretch" onClick={onCloseModal}>
              <Icon icon="close" size={20} />
            </div>
            <div className="my-4">
              <h1 className="pb-4">{title}</h1>
              <p className="text-base leading-5 ">{desc}</p>
            </div>
            <div className="flex flex-row items-center justify-between my-4" onClick={handleFocus}>
              {otp.map((_, index) => {
                return (
                  <React.Fragment key={index}>
                    <input
                      // onPasteCapture={(e) => console.log(e, 'testing')}
                      autoFocus={focus}
                      ref={index === activeOtpIndex ? inputRef : null}
                      onChange={handleOnChange}
                      onPaste={handlePaste}
                      onKeyDown={(e) => handleKeyDown(e, index)}
                      type="tel"
                      inputMode="tel"
                      value={otp[index]}
                      // className="flex flex-col items-center justify-center text-center w-[48px] h-[48px] bg-background border-r-0 border-l-0 border-t-0 focus:outline-none focus:border-b-[2px] focus:border-primary border-b-0"
                      className="flex flex-col items-center justify-center text-center min-[400px]:w-[48px]  min-[400px]:h-[48px]  min-[300px]:w-[37px]  min-[300px]:h-[37px] sm:w-[48px] sm:h-[48px] bg-background border-r-0 border-l-0 border-t-0 focus:outline-none focus:border-b-[2px] focus:border-primary border-b-0"
                    />
                  </React.Fragment>
                );
              })}
            </div>
            {/* <input
              ref={inputRef}
              onChange={onChange}
              autoFocus={focus}
              type="text"
              name=""
              id=""
              className="absolute h-0 w-0 opacity-0"
              value={codeOtp}
              maxLength={MAX_CODE_LENGTH}
            /> */}
            <div className="flex flex-col py-2">
              {!isExpired && (
                <p className="text-sm mb-1">
                  <span>{LocalizedString.idMigration.labelOtpExpiredIn} </span>
                  <span>
                    {timeBetween.minutes()} {LocalizedString.idMigration.labelMin}(s){' '}
                  </span>
                  <span>
                    {timeBetween.seconds()} {LocalizedString.idMigration.labelSecond}(s){' '}
                  </span>
                </p>
              )}
              <p className="text-sm">
                {LocalizedString.idMigration.textDidntReceiveCode}
                {/* Didn't recieve code? */}
                {seconds > 0 || minutes > 0 ? (
                  <span className="ml-1">
                    {LocalizedString.idMigration.textResendAnOtpCodeIn} {minutes < 10 ? `0${minutes}` : minutes}:
                    {seconds < 10 ? `0${seconds}` : seconds}
                  </span>
                ) : (
                  <button
                    className={seconds > 0 || minutes > 0 ? 'text-gray-300' : 'ml-1 text-primary underline'}
                    onClick={resendOTP}
                  >
                    {LocalizedString.idMigration.btnResendOtp}
                  </button>
                )}
              </p>
            </div>
          </div>
          <button
            disabled={loading}
            className="w-full py-3 bg-primary hyundaiTextHead text-center text-white"
            onClick={onConfirmFunc}
          >
            {loading ? LocalizedString.idMigration.labelPleaseWait : LocalizedString.idMigration.labelVerifyOtp}
          </button>
        </div>
      </div>
    </div>
  );
};

export default ModalOtp;
