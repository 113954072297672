import { FC } from 'react';
import Icon from '../../icon';
import { LocalizedString } from '../../../shared/localization';

interface ModalSearchProps {
  value: string;
  onChange: (e: any) => void;
  onConfirm: () => void;
  loading: boolean;
  onCloseModal: () => void;
  show: boolean;
}

const ModalSearch: FC<ModalSearchProps> = ({ value, onChange, onConfirm, show, onCloseModal, loading }) => {
  if (!show) {
    return null;
  }
  let btnBg = "bg-primary"
  if(!value.length || loading) {
    btnBg = "bg-[#33567F]"
  } 
  return (
    <div className="justify-center bg-[#000000cc] items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none" onClick={onCloseModal}>
      <div className="relative w-auto my-6 mx-4 max-w-3xl md:w-[400px] lg:w-[450px]">
        <div
          className="border-0 shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none  "
          onClick={(e) => e.stopPropagation()}
        >
          <div className="p-[14px] lg:p-[30px] md:w-[400px] lg:w-[450px]">
            <div className="flex flex-row justify-start items-start self-stretch" onClick={onCloseModal}>
              <Icon icon="close" size={20} />
            </div>
            <div className="my-4">
              <h1 className="pb-2">{LocalizedString.idMigration.labelSearchAdditionalAccount}</h1>
              <p className="text-base leading-5 ">{LocalizedString.idMigration.labelInsertYourEmailOrPhone}</p>
            </div>
            <div className="flex flex-col items-start self-stretch bg-red-500">
              <input
                onChange={onChange}
                type="text"
                placeholder={LocalizedString.idMigration.placeholderEmailOrPhoneNumber}
                value={value}
                className="self-stretch bg-background border-none text-base"
              />
            </div>
          </div>
          <button
            disabled={loading}
            className={`w-full py-3 ${btnBg} hyundaiTextHead text-center text-white`}
            onClick={onConfirm}
          >
            {loading ? LocalizedString.idMigration.labelLoadingPleaseWait : LocalizedString.idMigration.labelSearch}
          </button>
        </div>
      </div>
    </div>
  );
};

export default ModalSearch;
