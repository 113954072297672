import axios from "axios";


const baseurl = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}`,
  // baseURL: `${process.env.REACT_APP_API_URL}/bluectb`, // DEV
  // baseURL: `${process.env.REACT_APP_API_URL}/idm`, // UAT
  headers: {
    //     "Access-Control-Allow-Origin": "*",
    //     // "Accept-Language": "en-US",
    'Accept-Language': 'id',
        "Content-Type": "application/json",
        "X-DeviceId":"XXXXXX",
        "X-DeviceManufacturer": "XXXXXX",
        "X-DeviceModel": "XXXXXX",
        "X-TimeZone": "XXXXXX",
        "X-OSName": "XXXXXX",
        "X-OSVersion": "XXXXXX",
        "X-AppVersion": "XXXXXX",
  },
});

baseurl.interceptors.request.use(
  config => {
    config.headers['ClientId'] = `${localStorage.getItem('clientId')}`;
    config.headers['MigrationId'] = `${localStorage.getItem('mid')}`;
        return config;
    },
    error => {
        return Promise.reject(error);
    }
);

export const baseurlAuth = axios.create({
  // baseURL: process.env.REACT_APP_API_URL,
  baseURL: `${process.env.REACT_APP_API_URL_AUTH}`,
  // baseURL: `${process.env.REACT_APP_API_URL}/auth`,
  headers: {
    //     "Access-Control-Allow-Origin": "*",
    //     // "Accept-Language": "en-US",
    'Accept-Language': 'id',
        "Content-Type": "application/json",
        "X-DeviceId":"XXXXXX",
        "X-DeviceManufacturer": "XXXXXX",
        "X-DeviceModel": "XXXXXX",
        "X-TimeZone": "XXXXXX",
        "X-OSName": "XXXXXX",
        "X-OSVersion": "XXXXXX",
        "X-AppVersion": "XXXXXX",
  },
});

export const baseurlCore = axios.create({
  // baseURL: process.env.REACT_APP_API_URL,
  baseURL: `${process.env.REACT_APP_API_URL_CORE}`,
  // baseURL: `${process.env.REACT_APP_API_URL}/auth`,
  headers: {
    //     "Access-Control-Allow-Origin": "*",
    //     // "Accept-Language": "en-US",
    'Accept-Language': 'en-US',
    'Content-Type': 'application/json',
    'X-DeviceId': 'XXXXXX',
    'X-DeviceManufacturer': 'XXXXXX',
    'X-DeviceModel': 'XXXXXX',
    'X-TimeZone': 'XXXXXX',
    'X-OSName': 'XXXXXX',
    'X-OSVersion': 'XXXXXX',
    'X-AppVersion': 'XXXXXX',
  },
});



// ClientId:7D2AEC6F-F6A5-4CE6-B93F-33F86066F8E3
// AuthUuid:83371UQ/ltlhGRF124XIJDvb0e7iCN0mPJ2NgfhoAesdD20GgoxrJ1NZ1UfU7ahk
// MigrationId:f976c180-d99f-47e3-949a-afc3b9983bd6
// Accept-Language : id

export default baseurl;